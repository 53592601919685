/*--- Вспомогательные атомарные стили ---*/
/* #fccf00 - желтый брендовый */

/* Очищение */
.cf {
  *zoom: 1;
}
.cf:before,
.cf:after {
  content: ' ';
  display: table;
}
.cf:after {
  clear: both;
}

.noselect {
  -webkit-touch-callout: none;
  user-select: none;
}

/* Размер шрифта */
.fs_12 {
  font-size: 12px;
}
.fs_14 {
  font-size: 14px;
}
.fs_16 {
  font-size: 16px;
}
.fs_18 {
  font-size: 18px;
}
.fs_20 {
  font-size: 20px;
}
.fs_22 {
  font-size: 22px;
}
.fs_24 {
  font-size: 24px;
}
.fs_26 {
  font-size: 26px;
}
.fs_28 {
  font-size: 28px;
}
.fs_30 {
  font-size: 30px;
}
.fs_36 {
  font-size: 36px;
}
.fs_40 {
  font-size: 40px;
}
.fs_42 {
  font-size: 42px;
}

.fs_90p {
  font-size: 0.9em;
}
.fs_110p {
  font-size: 1.1em;
}
.fs_120p {
  font-size: 1.2em;
}
.fs_130p {
  font-size: 1.3em;
}
.fs_140p {
  font-size: 1.4em;
}
.fs_150p {
  font-size: 1.5em;
}

/* Форматирование текста */
.nobr {
  white-space: nowrap !important;
}
.bold {
  font-weight: 600;
}

/* Цвет текста */
.c_yellow {
  color: #fccf00;
}
.c_black {
  color: #000 !important;
}
.c_white {
  color: #fff;
}
.c_blue {
  color: #0089ec;
}
.c_gray {
  color: #777;
}
.c_red {
  color: #d00;
}
.c_green {
  color: #4a1;
}

/* Выравнивание текста */
.ta_c {
  text-align: center;
}
.ta_r {
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
}
.ta_l {
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
}

/* MARGIN */
.ml_10 {
  margin-left: 10px;
}
.mr_10 {
  margin-right: 10px;
}
.mb_10 {
  margin-bottom: 10px !important;
}
.mt_10 {
  margin-top: 10px;
}
.mv_10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.mh_10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mb_5 {
  margin-bottom: 5px !important;
}
.mt_5 {
  margin-top: 5px !important;
}
.mv_5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.mr_5 {
  margin-right: 5px;
}
.ml_5 {
  margin-left: 5px;
}
.mh_5 {
  margin-left: 5px;
  margin-right: 5px;
}
.m_5 {
  margin: 5px;
}
.mt_1em {
  margin-top: 1em;
}
.ml_1em {
  margin-left: 1em;
}
.mr_1em {
  margin-right: 1em;
}
.mb_1em {
  margin-bottom: 1em;
}
.mt_2em {
  margin-top: 2em;
}
.mb_2em {
  margin-bottom: 2em;
}

/* PADDING */
.p_0 {
  padding: 0 !important;
}
.p_10 {
  padding: 10px;
}
.p_5 {
  padding: 5px;
}
.pr_5 {
  padding-right: 5px !important;
}
.pt_5 {
  padding-top: 5px;
}
.pv_5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.pv_10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

/* Ссылки */
.cp {
  cursor: pointer;
}

/* Размеры */
.w_100p {
  width: 100%;
  max-width: 100%;
}
.h_100p {
  height: 100%;
  max-height: 100%;
}
.w_64 {
  width: 64px;
}
.h_64 {
  height: 64px;
}

/* Отображение */
.d_b {
  display: block;
}
.d_n {
  display: none;
}
.d_ni {
  display: none !important;
}
.d_ib {
  display: inline-block;
}
.d_i {
  display: inline;
}
.d_cell {
  display: table-cell;
  vertical-align: middle !important;
}
.z_5 {
  z-index: 5;
}
.z_10 {
  z-index: 10;
}

/* Вертикальное выравнивание */
/* tbody.va_m > tr > td,
tr.va_m > td, */
.va_m {
  vertical-align: middle !important;
}

/* tbody.va_b > tr > td
tr.va_b > td, */
.va_b {
  vertical-align: bottom !important;
}

/* tbody.va_t > tr > td
tr.va_t > td, */
.va_t {
  vertical-align: top !important;
}

.flex_v_center {
  display: flex;
  align-items: center;
}

/* Позиционирование */
.p_r {
  position: relative;
}
.p_a {
  position: absolute;
}

/* Обрезка */
.o_h {
  overflow: hidden;
}
.o_a {
  overflow: auto;
}
.to_ellipsis {
  text-overflow: ellipsis;
}

/* Обводка */
.b_none {
  border: none !important;
}
.bb {
  border-bottom: 1px solid $c-black-01;
}

/* opacity */
.o_50 {
  opacity: 0.5 !important;
}
.o_80 {
  opacity: 0.8 !important;
}

/* effects */

.e_flash {
  -webkit-animation: e_flash 1.5s linear infinite;
  animation: e_flash 1.5s linear infinite;
}

@-webkit-keyframes e_flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes e_flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

/* Loading ############################################### */
.is-loading {
  position: relative;
  cursor: progress;
}
.is-loading:before {
  content: '';
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
}

.is-loading:after {
  height: 12px !important;
  width: 12px !important;
  border-radius: 50% !important;
  border: 2px solid #a1a1a1;
  border-right-color: transparent;
  /* border-left-color: #ebebeb; */
  content: '';
  display: block;
  animation: spinAround 500ms infinite linear;
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  position: absolute !important;
  z-index: 2;
}

@keyframes spinAround {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
