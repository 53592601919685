$primary: $primary-color;
$secondary: #ececec;
$light: #f5f5f5;
$link-color: $primary-color-text;
$input-btn-padding-y: 0.3125rem;
$input-btn-padding-y-sm: 0.203125rem;
$border-radius: 0.125rem;
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
$line-height-base: 1.4;
@import '../../../node_modules/bootstrap/scss/bootstrap-reboot';
@import '../../../node_modules/bootstrap/scss/utilities';

// @include text-emphasis-variant(".text-primary", $primary-color-text);
.text-primary {
  color: $primary-color-text !important;
}
@if $emphasized-link-hover-darken-percentage != 0 {
  a.text-primary {
    @include hover-focus() {
      color: darken($primary-color-text, $emphasized-link-hover-darken-percentage) !important;
    }
  }
}
