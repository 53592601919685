$c-white: #ffffff;
$c-white-01: #fffdfd;

$c-black: black;
$c-black-01: #3e4041;
$c-black-02: #5d6061;

$c-gray-01: #f0f0f0;
$c-gray-02: #eeeeee;
$c-gray-03: #ececec;
$c-gray-04: #e2e2e2;
$c-gray-05: #c4c4c4;
$c-gray-07: #8f8f8f;

$c-green-01: green;

$c-blue-02: #209cee;
$c-blue-03: #1879B9;

$c-red-01: #ff3860;
$c-red-02: #ffdde4;

$c-link: rgb(9, 9, 224);
$c-link-hover: rgb(15, 15, 116);

$c-yellow-01: #ffffe5;
$c-yellow-02: #FFFACD;

$c-danger: $c-red-01;

$primary-color: #209d9d;
$primary-color-text: #0e7a86;

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function getUrlBySvg($svg) {
  $svgEscaped: str-replace(str-replace(str-replace(str-replace($svg, '<', '%3C'), '>', '%3E'), '"', '\''), '#', '%23');
  $url: url("data:image/svg+xml, #{$svgEscaped}");

  @return $url
}

@function getTickImage($tick-color) {
  @return getUrlBySvg('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 490.434 490.433"><path d="M472.003 58.36l-13.132-11.282c-21.798-18.732-54.554-16.644-73.799 4.697L165.39 295.359l-66.312-57.112c-21.775-18.753-54.536-16.707-73.804 4.611l-11.611 12.848a52.934 52.934 0 0 0-13.595 38.18 52.938 52.938 0 0 0 17.402 36.6l121.553 111.311a52.936 52.936 0 0 0 76.355-4.057l262.245-304.71a52.915 52.915 0 0 0 12.661-38.496 52.92 52.92 0 0 0-18.281-36.174z" fill="#{$tick-color}"/></svg>');
}

@function getArrowDownImage($color: "") {
  @return getUrlBySvg('<svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362"><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z" fill="#{$color}"/></svg>')
}

.stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 450px;
  text-align: center;
  flex: 1 1 0px;
  box-shadow: 0 .15em .4em 0 rgba(0, 0, 0, 0.1);

  &:first-child {
    margin-right: 2em;
  }

  &-header {
    padding: 1em 0;
    border-bottom: .125em solid $c-gray-02;

    &:only-child {
      border-bottom: none;
    }
  }

  &-totalAmount {
    font-size: 2.5em;
    color: $primary-color-text;
    margin: 0;
    line-height: 1;
  }

  &-heading {
    margin: 0;
    font-weight: normal;
    font-size: 1.5em;
  }

  &-dataWrapper {
    display: flex;
  }

  &-dataContainer {
    padding: .5em 0 1em 0;
    border-right: .125em solid $c-gray-02;

    &:last-child {
      border-right: none;
    }
  }

  &-dataHeading {
    margin: 0;
    font-size: 1em;
    font-weight: normal;
  }

  &-dataValue {
    margin: 0;
    font-size: 1.25em;
    font-weight: bold;
  }

  &-dataContainer {
    &--pendingRequests:hover {
      color: $c-danger;
    }
    &--approvedRequests, &--deniedRequests, &--cancelledRequests {
      &:hover {
        color: $primary-color-text;
      }
    }
  }
}

/*
 * Super hack found here:
 * https://stackoverflow.com/questions/8720931/can-css-detect-the-number-of-children-an-element-has
 *
 */

.stats-dataContainer:first-child:nth-last-child(4),
.stats-dataContainer:first-child:nth-last-child(4) ~ .stats-dataContainer {
  width: 25%;
}

.stats-dataContainer:first-child:nth-last-child(2),
.stats-dataContainer:first-child:nth-last-child(2) ~ .stats-dataContainer {
  width: 50%;
}
