$c-white: #ffffff;
$c-white-01: #fffdfd;

$c-black: black;
$c-black-01: #3e4041;
$c-black-02: #5d6061;

$c-gray-01: #f0f0f0;
$c-gray-02: #eeeeee;
$c-gray-03: #ececec;
$c-gray-04: #e2e2e2;
$c-gray-05: #c4c4c4;
$c-gray-07: #8f8f8f;

$c-green-01: green;

$c-blue-02: #209cee;
$c-blue-03: #1879B9;

$c-red-01: #ff3860;
$c-red-02: #ffdde4;

$c-link: rgb(9, 9, 224);
$c-link-hover: rgb(15, 15, 116);

$c-yellow-01: #ffffe5;
$c-yellow-02: #FFFACD;

$c-danger: $c-red-01;

$primary-color: #209d9d;
$primary-color-text: #0e7a86;

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

@function getUrlBySvg($svg) {
  $svgEscaped: str-replace(str-replace(str-replace(str-replace($svg, '<', '%3C'), '>', '%3E'), '"', '\''), '#', '%23');
  $url: url("data:image/svg+xml, #{$svgEscaped}");

  @return $url
}

@function getTickImage($tick-color) {
  @return getUrlBySvg('<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 490.434 490.433"><path d="M472.003 58.36l-13.132-11.282c-21.798-18.732-54.554-16.644-73.799 4.697L165.39 295.359l-66.312-57.112c-21.775-18.753-54.536-16.707-73.804 4.611l-11.611 12.848a52.934 52.934 0 0 0-13.595 38.18 52.938 52.938 0 0 0 17.402 36.6l121.553 111.311a52.936 52.936 0 0 0 76.355-4.057l262.245-304.71a52.915 52.915 0 0 0 12.661-38.496 52.92 52.92 0 0 0-18.281-36.174z" fill="#{$tick-color}"/></svg>');
}

@function getArrowDownImage($color: "") {
  @return getUrlBySvg('<svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362"><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z" fill="#{$color}"/></svg>')
}

@import "../../assets/css/functions";

.it_checkbox {
  opacity: 0;
  margin: 0 2px 0 0;
  height: 2em;
  width: 2em;

  &:focus {
    outline: none;

    & ~ .it_checkbox_mark {
      border-color: #72d0eb;
      box-shadow: 0 0 0 0.125em rgba(114,208,235,0.25);
    }
  }

  &:enabled ~ &_mark, &:enabled ~ &_label {
    cursor: pointer;
  }

  &:disabled ~ &_mark, &:disabled ~ &_label {
    opacity: 0.7;
  }

  &_cover {
    position: relative;
    display: inline-flex;
    align-items: center;

    &:hover .it_checkbox:enabled + .it_checkbox_mark {
      background-color: #e1e1e1;
    }
  }

  &_mark {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    border-radius: 3px;
    border: 2px solid #dedede;
    background: #f1f1f1;
    width: 1.6em;
    height: 1.6em;

    .it_checkbox:checked ~ & {
      background: #e8e8e8 getTickImage(#3abca7) no-repeat center center;
      background-size: 80%;
    }
  }
}

.element_confirm_button {
  position: relative;
  display: inline-block;

  &__confirmation {
    position: absolute;
    align-items: center;
    right: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    background: $c-yellow-01;
    padding: 0.25em;
    border-radius: 4px;
    box-shadow: 0 0 0.2em 0 rgba(0, 0, 0, 0.7);
    z-index: 2;
    white-space: nowrap;
  }

  &__title {
    display: inline-block;
    vertical-align: sub;
    margin: 0 0.5em;
  }

  &__yes {
    margin-right: 0.5em;
  }
}


.inlineHelp {
  padding-top: 0.2em;

  &.is-error {
    color: $c-red-01;
  }
}

.it_radiobutton {
  margin: 0 2px 0 0;
  height: 1.2em;
  width: 1.2em;

  &:enabled ~ &_label {
    cursor: pointer;
  }

  &:disabled ~ &_label {
    opacity: 0.7;
  }

  &_label {
    margin-left: 5px;
  }

  &_cover {
    position: relative;
    display: inline-flex;
    align-items: right;

    &:hover .it_radiobutton:enabled {
      background-color: #e1e1e1;
      cursor: pointer;
    }
  }

}