// colors
.text-danger {
  color: $c-danger !important;
}

.text-white {
  color: $c-white !important;
}

.bg-danger {
  background-color: $c-danger !important;
}

// text
.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

// display
.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}
