@import '../../../node_modules/react-table/react-table';

.ReactTable {
  border: none;

  .rt-table {
    border: 1px solid $c-gray-03;
  }

  .rt-th {
    &.-sort-asc,
    &.-sort-desc {
      box-shadow: none !important;
    }
  }

  .rt-thead.-header {
    z-index: initial;
    box-shadow: none;
    border-bottom: 1px solid $c-gray-03;
    font-weight: bold;
  }

  .rt-thead {
    .rt-tr {
      text-align: left;
    }

    .rt-th {
      display: flex;
      align-items: center;
      white-space: normal;
      padding: 10px 20px;

      &:not(:last-child) {
        border-right: 1px solid $c-gray-03;
      }
    }
  }

  .rt-td {
    min-height: 50px;
    display: flex;
    align-items: center;
    white-space: normal;
    word-break: break-all;
    padding-left: 20px;
    padding-right: 20px;
  }

  .rt-tbody {
    .rt-tr-group {
      &,
      &:last-child {
        border-bottom: 1px solid $c-gray-03;
      }
    }

    .rt-td:not(:last-child) {
      border-right: 1px solid $c-gray-03;
    }
  }

  .rt-noData {
    top: 70px;
    padding: 0;
  }

  &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    position: relative;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 4px;
      top: 0;
      bottom: 0;
      background: $primary-color;
      z-index: 2;
    }

    .rt-td {
      background: #cef1f3;
    }
  }

  .rt-th {
    &:not(.-sort-asc):not(.-sort-desc) {
      .sortIcon.sortIcon--nonActive {
        display: inline-block;
        width: 12px;
        height: 12px;
        background: getUrlBySvg(
            '<svg xmlns="http://www.w3.org/2000/svg" width="401.998" height="401.998"><path d="M73.092 164.452h255.813c4.949 0 9.233-1.807 12.848-5.424 3.613-3.616 5.427-7.898 5.427-12.847s-1.813-9.229-5.427-12.85L213.846 5.424C210.232 1.812 205.951 0 200.999 0s-9.233 1.812-12.85 5.424L60.242 133.331c-3.617 3.617-5.424 7.901-5.424 12.85 0 4.948 1.807 9.231 5.424 12.847 3.621 3.617 7.902 5.424 12.85 5.424zM328.905 237.549H73.092c-4.952 0-9.233 1.808-12.85 5.421-3.617 3.617-5.424 7.898-5.424 12.847s1.807 9.233 5.424 12.848L188.149 396.57c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428l127.907-127.906c3.613-3.614 5.427-7.898 5.427-12.848 0-4.948-1.813-9.229-5.427-12.847-3.614-3.616-7.899-5.42-12.848-5.42z"/></svg>'
          )
          no-repeat center center / 12px;
      }
    }

    &.-sort-asc .sortIcon.sortIcon--asc {
      display: inline-block;
      width: 9px;
      height: 9px;
      background: getUrlBySvg(
          '<svg xmlns="http://www.w3.org/2000/svg" width="292.362" height="292.362"><path d="M286.935 197.286L159.028 69.379c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233 1.807-12.85 5.424L5.424 197.286C1.807 200.9 0 205.184 0 210.132s1.807 9.233 5.424 12.847c3.621 3.617 7.902 5.428 12.85 5.428h255.813c4.949 0 9.233-1.811 12.848-5.428 3.613-3.613 5.427-7.898 5.427-12.847s-1.814-9.232-5.427-12.846z"/></svg>'
        )
        no-repeat center center / 9px;
    }

    &.-sort-desc .sortIcon.sortIcon--desc {
      display: inline-block;
      width: 9px;
      height: 9px;
      background: getArrowDownImage() no-repeat center center / 9px;
    }
  }

  .accountsList,
  .requestList {
    &-sortBtn {
      background: none;
      border: none;
      padding: 0;
      font-size: 1em;

      &:hover {
        cursor: pointer;
        color: $c-black-01;
      }

      &:hover .accountsList-sortText,
      &:hover .requestList-sortText {
        border-bottom: 0.125em solid $body-color;
      }

      &:hover .accountsList-sortIcon,
      &:hover .requestList-sortIcon {
        color: $body-color;
      }
    }

    &-sortText {
      padding-bottom: 0.1em;
      border-bottom: 0.125em dotted $c-gray-05;

      &--active {
        border-bottom: 0.125em solid $body-color;
      }
    }

    &-sortIcon {
      color: $c-gray-07;

      &--active {
        color: $body-color;
      }
    }
  }
}
