@import "buttons";
@import "tables";

hr {
  border-bottom: 1px solid $c-black-01;
  margin: 1em 0;
}

textarea.input,
.input[type="text"],
.select {
  border: 2px solid $c-gray-04;
  border-radius: 4px;
  font-size: 1em;
  min-height: 2em;
  padding: 0.25em;

  &:hover:not([disabled]) {
    border-color: $c-gray-05;
  }

  &:focus {
    box-shadow: 0 0 0 0.1em rgba(200, 200, 200, 0.5);
    outline: none;
    border-color: $c-gray-05;
  }

  &.is-error {
    border-color: $c-red-01!important;
  }

  &::placeholder {
    color: $c-gray-05;
  }
}

textarea.input {
  padding: 0.5em;
}

// Delete #########################################
.delete {
  background-color: rgba(10,10,10,.2);
  border: none;
  border-radius: 290486px;
  cursor: pointer;
  display: inline-block;
  font-size: 0;
  height: 24px;
  width: 24px;
  outline: 0;
  position: relative;
  vertical-align: top;
  padding: 0;

  &:hover {
    background-color: rgba(10,10,10,.3);
  }

  &::before {
    height: 2px;
    width: 50%;
  }

  &::after {
    height: 50%;
    width: 2px;
  }

  &::before, &::after {
    background-color: #fff;
    content: "";
    display: block;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center;
  }
}

// Notification #############################################
.notification {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 1em 2.5em 1em 1.25em;
  position: relative;

  > .delete {
    position: absolute;
    right: .5rem;
    top: .5rem;
  }
}

.table {
  width: 100%;
  font-size: 14px;

  &-sectionTitle {
    background: $c-black-02 !important;
    color: $c-white;
  }

  tr {
    border-bottom: 1px dashed $c-gray-04;
  }

  th {
    padding: 0.25em 0.5em;
    font-weight: bold;
    background-color: $c-gray-01;
    border-bottom: 1px solid $c-gray-04;
  }

  td {
    padding: 0.25em 0.5em;
  }
}

.single-message {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 10em;
    margin: 0;
  }

  h1 {
    margin-top: 0;
  }
}
