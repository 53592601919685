@import "../../../node_modules/bootstrap/scss/buttons";

.btn[disabled], .btn.disabled {
  color: $c-gray-07;
  cursor: not-allowed;
  opacity: 1;
}

.btn-light {
  background: transparent;
  border-color: transparent;
}

.accountLayout {
  .btn-light {
    text-decoration: underline;
  }
}
