html {
  height: 100%;
  width: 100%;
  overflow: auto;
}

body {
  overflow: auto;
}

#app_root {
  display: flex;
}

#mainLayout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.m-mainMiddleBlock {
  flex-grow: 1;
  margin: 0 auto;
  padding: 0.5em;
  width: 100%;
  max-width: 1200px;
}

.mainHeader {
  width: 100%;
  padding-top: 1em;
  border-bottom: 4px solid $c-black-01;

  &_block {
    display: flex;
    align-items: flex-end;
    padding-bottom: 1em;
    text-align: right;
  }

  &_content {
    flex: 1 1;
  }

  &_logo {
    height: 47px;
    margin: 1.5em 0;
  }
}

.mainMenu {
  &_layer {
    padding: 0.2em 0;

    a, .btn-link {
      text-decoration: underline;
    }
  }

  &_link {
    padding: 0 0.3em;

    &:not(:last-child) {
      margin-right: 0.3em;
    }
  }
}

.mainContent {
  padding: 0.5em;
}

.mainFooter {
  background: $c-black-01;
  color: $c-white;
  padding: 1em 0;
  text-align: right;

  &_logo {
    height: 1em;
  }

  a {
    color: $c-white;

    &:hover {
      color: darken($c-white, 10%);
    }
  }

  > .m-mainMiddleBlock {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: baseline;

    > *:not(:first-child) {
      margin-left: 10px;
    }

    .mainFooter_logo {
      margin-left: 20px;
    }
  }
}
/***********************************************************************/
/* Стили для проекта */
/***********************************************************************/
/*мелочевка*/
@keyframes css_load {
  0% { width: 100%; }
  100% { width: 0%; }
}

.layout_loader {
  min-height: 2.5rem;
  min-width: 2.5rem;

  &-disabled {
    position: relative;
    // overflow: hidden;
    opacity: 0.7;
    pointer-events: none;

    &:before {
      content: '';
      background: rgba(255,255,255,0.6);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  &__spinner {
    height: 1.5rem;
    width: 1.5rem;
    overflow: hidden;
    border-radius: 50%;
    z-index: 2;
  }

  &__spinner_container {
    top: 0;
    left: 0;
    overflow: visible;
    height: 0 !important;
    position: absolute;
  }
}

.widgetsColumn {
  width: 300px;
  padding-left: 1em;
}

.addUserModal, .removeUserModal, .addFundsModal, .editGroupEmailModal, .requestReportModal, .editProfileModal {
  .enote_modal__window {
    max-width: 550px;
  }
}

.orderHasNoteIcon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center center;
  width: 1.25em;
  height: 1.25em;
  background-size: 1.25em;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzODUgMzg1IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+PHBhdGggZmlsbD0iI2ZmOTgxMSIgZD0iTTc3LjMyNiAzNTVsNi4wMDEgMzAgMTQ5Ljk5MS0zMEgxNTcuNXpNMzA3LjUgMzQwLjE2M2w3MC0xNC4wMDEtNTguODM3LTI5NC4xNzRMMjAzLjYxMiA1NUgzMDcuNXoiLz48cGF0aCBkPSJNMTU3LjUgMTUwYy0yNC44MTMgMC00NS0yMC4xODYtNDUtNDVWODVoMzB2MjBjMCA4LjI3MSA2LjcyOSAxNSAxNSAxNVY1NUg3LjV2MzAwaDE1MFYxNTB6IiBmaWxsPSIjZmZlOThmIi8+PHBhdGggZD0iTTMwNy41IDM0MC4xNjNWNTVoLTEwNXY1MGMwIDI0LjgxNC0yMC4xODcgNDUtNDUgNDV2MjA1aDE1MHYtMTQuODM3ek0xNzIuNSAxMDVWNTVoLTE1djY1YzguMjcxIDAgMTUtNi43MjkgMTUtMTV6IiBmaWxsPSIjZmZkYTQ0Ii8+PHBhdGggZD0iTTE0Mi41IDQ1YzAtOC4yNzEgNi43MjktMTUgMTUtMTVzMTUgNi43MjkgMTUgMTV2NjBjMCA4LjI3MS02LjcyOSAxNS0xNSAxNXMtMTUtNi43MjktMTUtMTVWODVoLTMwdjIwYzAgMjQuODE0IDIwLjE4NyA0NSA0NSA0NXM0NS0yMC4xODYgNDUtNDVWNDVjMC0yNC44MTMtMjAuMTg3LTQ1LTQ1LTQ1cy00NSAyMC4xODctNDUgNDV2MTBoMzBWNDV6IiBmaWxsPSIjNTY1NjU5Ii8+PC9zdmc+);
}

.errorBaner {
  margin: 0.5em;
  padding: 1em;
  background: $c-red-02;
  border: 1px solid $c-danger;
  box-shadow: 0 0 0.5em 0 $c-danger;
  border-radius: 4px;

  &_message {
    font-size: 1.1em;
  }
}

[role="button"]:hover {
  cursor: pointer;
}
